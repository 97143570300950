<template>
  <div>

    <v-row dense class="mt-3">
      <v-col class="col-12 col-sm-6 col-md-5 col-lg-4">
        <v-card class="fill-height">
          <v-img :src="imageSrc" height="200px"></v-img>
          <v-card-title v-text="title"></v-card-title>
        </v-card>
      </v-col>

      <v-col class="col-12 col-sm" :class="invert ? 'order-sm-first' : ''">
        <v-card class="fill-height">
          <v-card-text class="black--text">
            <slot></slot>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name: "AboutMeSection",
  props: ["title", "imgName", "invert"],
  computed: {
    imageSrc() {
      return require('../assets/images/about_me/' + this.imgName + '.jpg');
    }
  }
};
</script>

<style scoped>

</style>