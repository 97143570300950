<template>
  <v-main>

    <v-container class="mt-3 mb-6">

      <v-card class="mb-3">
        <v-card-text>
          <div class="d-flex justify-center">
            <span class="text-h5 black--text">Über Mich</span>
          </div>
        </v-card-text>
      </v-card>

      <AboutMeSection title="Mein Selbst" img-name="Selbst">
        <div class="text-body-1 font-weight-medium mb-1">Ich bin voller Energie</div>
        <div class="text-body-1">
          „Steh-Auf-Männchen“ war die Beschreibung einer Geschäftspartnerin. Das trifft es auf den Punkt. Als Mensch kann man von mir im Rahmen meiner Kernwerte Folgendes erwarten:<br>
          Ich entwickele mich stets persönlich weiter. Ich fordere und fördere mich permanent. Ich bin dankbar. Ich unterstütze bedingungslos. Ich bin positiv lösungsorientiert. Ich habe Freude und Spaß.
          Ich stehe für Eigenverantwortung. Ich treffe mutige Entscheidungen und erlaube mir Fehler.<br>
          Und das Allerwichtigste: Ich gebe niemals auf und kämpfe beharrlich für meine Ziele.
        </div>
      </AboutMeSection>

      <AboutMeSection title="Mein Umfeld" img-name="Umfeld2" :invert="true">
        <div class="text-body-1 font-weight-medium mb-1">Ich bin voller Liebe</div>
        <div class="text-body-1">
          Bei den Menschen die ich in mein Leben lasse, schaue ich mittlerweile sehr genau hin. Damit ich meine Liebe mit den richtigen Menschen teilen kann. Der Mittelpunkt meines Lebens sind natürlich meine Anna, mein William und mein Henry. Eine konstante auf die immer Verlass war, ist und sein wird, sind meine Eltern, welche mir immer die Freiheit gaben mich zu entwickeln. Für diese Menschen bin ich besonders dankbar.
        </div>
      </AboutMeSection>

      <AboutMeSection title="Meine Karriere" img-name="Karriere">
        <div class="text-body-1 font-weight-medium mb-1">Ich helfe Menschen</div>
        <div class="text-body-1">
          Seit Beginn meiner Selbständigkeit 2013 helfe ich Ingenieuren und Informatikern dabei, ihre persönlichen, beruflichen und wirtschaftliche Ziele zu erreichen. Dabei habe ich mir ein Netzwerk aufgebaut, welches meine Kunden dabei fordert und fördert, ihr Erfolgs-Mindset zu entwickeln, eine berufliche Wohlfühlatmosphäre zu schaffen und dabei ihre wirtschaftlichen Ziele auf dem schnellsten und kürzesten Weg ohne Inkaufnahme unnötiger Risiken zu erreichen.
        </div>
      </AboutMeSection>

      <AboutMeSection title="Meine Finanzen" img-name="Finanzen" :invert="true">
        <div class="text-body-1 font-weight-medium mb-1">Ich liebe Geld</div>
        <div class="text-body-1">
          Für mich gilt auch die Binsenweisheit: „Wer streut, rutscht nicht aus“. So setze ich bei meinen Investments auf Immobilien, Aktien, Aktienfonds, Crypto-Währungen und steuerlich geförderte Vorsorge. Wobei die Auswahl und Gewichtung je nach Marktlage variiert. Der wichtigste Punkt ist aber sowieso die innere Einstellung zum Geld und zum Sparverhalten. Auch verschiedene Einkommensquelle zu besitzen zählt zu meiner Strategie. Ich liebe Geld.
        </div>
      </AboutMeSection>

      <AboutMeSection title="Meine Gesundheit" img-name="Gesundheit">
        <div class="text-body-1 font-weight-medium mb-1">Ich achte auf mein körperliches und geistiges Wohlbefinden</div>
        <div class="text-body-1">
          Diese Achtung beruht darauf, dass ich mir am Tag 2 Stunden Zeit für MICH nehme. 1 Stunde lesen, 30 min Sport und 30 min Meditation. Bei meiner Ernährung habe ich persönlich sehr gute Erfahrung mit dem Intervallfasten 16 zu 8 gemacht, zudem esse ich kaum Fleisch  und nehme nur wenig Alkohol zu mir. Um mich damit nicht unter Druck zu setzen gilt für mich die Regel 8 von 10, d.h. wenn mir die Dinge an 8 von 10 gelingen, bin ich happy.
        </div>
      </AboutMeSection>

      <AboutMeSection v-if="false" title="Meine Referenzen" img-name="climber_1080" :invert="true">
        <div class="text-body-1 font-weight-medium mb-1"></div>
        <div class="text-body-1">

        </div>
      </AboutMeSection>

    </v-container>

  </v-main>
</template>

<script>
import AboutMeSection from "@/components/AboutMeSection";
export default {
  name: "AboutMe",
  components: { AboutMeSection },
  data: () => {
    return {
    };
  }
};
</script>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}

.subheadline {

}
</style>